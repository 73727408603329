/* eslint-disable */
import { css } from '@emotion/react';
import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import { Box } from '../../style/basicStyle';

const Container = styled.div`
  flex: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 315px;
  height: 340px;
  box-shadow: inset 0 0 18px 1px #e3e7ed;
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: small;
  padding: 10px;
  box-sizing: border-box;

  :hover {
    background: rgb(218, 216, 216);
  }
`;

const ImageContainer = styled.div`
  height: 237px;
  width: 237px;
`;

const imageCss=css`
  width: 100%;
  height: auto;
  border-radius: 50%;`
;

export const ProfileCard = ({ image, name, title, jerseyNumber, isNonPlayer, slug, index, ...props }) => {
  const position = (isNonPlayer && title) || (title && title !== 'null' && `Position: ${title}`) || '';
  return (
    <Link to={slug}>
      <Container>
        <ImageContainer>{image && image.childImageSharp && image.childImageSharp.gatsbyImageData ? <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="profile image" /> : <StaticImage alt="profile image placeholder" css={imageCss} src="../../../bulldogshockeyassets/images/teamPlaceholder.jpg" />}</ImageContainer>
        <Box fontSize={[3, 4, 5]}>{`${name}`}</Box>
        {!isNonPlayer && jerseyNumber && (
          <Box fontSize={[2, 3, 4]}> #{jerseyNumber}</Box>
        )}
        <Box fontSize={[2, 2, 2, 2]}> {position}</Box>
      </Container>
    </Link>
  );
};
